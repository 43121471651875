<template>
  <div class="app-content-box bg-gray profile-comments">
    <div class="container add-shelf-wrapper">
      <h2 class="top-heading-big">
       <span v-if="getType == 1">Dodaj nowe TOP 5</span>
       <span v-if="getType == 0">Dodaj nową półkę</span>
      </h2>
    </div>
    <ValidationObserver ref="observer" tag="form">
      <form @submit.prevent="">
              <div class="container mt-3">
        <div class="input-container">
          <ValidationProvider rules="required" v-slot="{errors}">
            <input type="text" v-model="shelfTitle" class="form-input w-100" :placeholder="getType  === 0 ? 'nazwa półki' : 'nazwa nowego TOP 5'" />
            <span class="form-error-info text-center">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
    </div>
    <div class="book-form-container mt-4">
      <div class="form-group form-group-border">
        <div class="container form-container">
          <div class="form-checkbox-switch">
            <input
              type="checkbox"
              name="checkbox-switch_"
              id="checkbox-switch_1"
              v-model="isChecked"
            />
            <label for="checkbox-switch_1">
              publiczna
              <span class="switch-pill">
                <span class="switch-dot"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group form-group-footer submit-button-bottom">
    <div class="container form-container">
    <button type="submit" @click="submit" class="btn-black-full w-100 mt-3">Zapisz</button>
    </div>
  </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

export default {
  name: 'LibraryAddShelf',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    id: [Number, String]
  },
  data () {
    return {
      shelfTitle: '',
      isChecked: false,
      allShelves: [],
      getType: ''
    }
  },
  async created () {
    this.getAllShelves()
    this.getType = parseInt(this.id, 10)
  },
  computed: {
    my_id () { return this.$store.state.my_id }
  },
  methods: {
    async getAllShelves () {
      await this.$https('/shelf', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(res => {
          const items = res.data.response
          this.allShelves = items.map(e => e.name)
        })
        .catch(error => {
          console.log('', error)
        })
    },
    async submit () {
      const valid = await this.$refs.observer.validate()
      const isShelfExsist = this.allShelves.includes(this.shelfTitle)
      if (valid === true && isShelfExsist) {
        alert('Posiadasz już półkę o takiej nazwie')
      } else if (!valid) {
        alert('Podaj nazwę półki')
      } else {
        this.saveShelf()
        alert('Półka została dodana')
      }
      this.getAllShelves()
    },
    saveShelf () {
      const checkboxVal = this.isChecked ? 'public' : 'private'
      const getData = `name=${this.shelfTitle}&privacy=${checkboxVal}&is_top=${this.id}`
      this.$https('/shelf', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$router.replace('/biblioteka')
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-content-box {
  min-height: 100vh;
}
.add-shelf-wrapper {
  padding-top: 30px;
}
  .submit-button-bottom {
    width: 100%;
    position: absolute;
    bottom: 10%;
    border-top: none;
  }
</style>
